import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import useDayCalendar from '../../../Hooks/useDayCalendar';
import TopMenu from '../../../Components/TopMenu/TopMenu';
import Notification from '../../../Assets/icons/Notification.png';
import NotificationForm from '../NotificationForm';
import Accounting from '../../../Assets/image/accounting.png';
import FinInfo from '../../../Assets/image/finInfo.png';
import Finance from '../../../Assets/image/finance.jpg';
import Calend from '../../../Assets/image/calend.png';
import Taxpayers from '../../../Assets/image/taxpayers.jpg';
//start home icom
import Forecast from '../../../Assets/homeIcons/forecast.jpg';
import Rate from '../../../Assets/homeIcons/rate.png';
import Notary from '../../../Assets/homeIcons/notary.png';
import Arlis from '../../../Assets/homeIcons/arlis.png';
import ERegister from '../../../Assets/homeIcons/eRegister.jpg';
import Datalex from '../../../Assets/homeIcons/datalex.jpg';
import Cadastre from '../../../Assets/homeIcons/cadastre.png';
import Armstat from '../../../Assets/homeIcons/armstat.png';
import FinHelp from '../../../Assets/homeIcons/finHelp.png';
import TaxCalendar from '../../../Assets/homeIcons/taxCalendar.png';
import Ekeng from '../../../Assets/homeIcons/ekeng.png';
import SalaryIcon from '../../../Assets/homeIcons/salary.png';
import FrequentlyAsked from '../../../Assets/homeIcons/frequentlyAsked.png';

import './usefulTool.scss';
import OrganizationAddForm from '../../Company/OrganizationAddForm';

const UsefulTool = () => {
  const { companySign, userID, currentCompanyID } = useSelector(
    (state) => state.auth,
  );

  const [showNotData, setShowNotData] = useState(false);
  const { notificationData } = useDayCalendar(userID);
  const targetNotBlank = companySign ? '/OrganizationAddForm' : '/Dashboard';

  const renderNotificationIcon = () => (
    <div className="notification" onClick={() => setShowNotData(true)}>
      <img className="noti-img" src={Notification} alt="" />
      <p className="badge">{notificationData?.length}</p>
    </div>
  );

  const usefulToolsList = {
    basicTools: [
      {
        title: 'Հիմնական գործիքներ',
      },
      {
        name: 'Հաշվապահական հաշվառում',
        icon: Accounting,
        path: targetNotBlank,
      },
      {
        name: 'Աշխատավարձ',
        icon: SalaryIcon,
        path: '/EmployeesDashboard',
      },
      {
        name: 'Անձնական բյուջե',
        icon: FinHelp,
        path: '/DataEntry',
      },

      { name: 'Օրացույց', icon: Calend, path: '/Calendar' },
    ],

    informationSites: [
      {
        title: 'Կարևոր կայքեր',
      },

      {
        name: 'Ֆինանսական Այբ Բեն Գիմ',
        icon: Finance,
        path: 'https://abcfinance.am/',
      },
      {
        name: 'Տեղեկատվություն ՀՀ բանկերի ավանդների և վարկերի մասին',
        icon: FinInfo,
        path: 'https://www.fininfo.am/',
      },

      {
        name: 'Հարկային օրացույց',
        icon: TaxCalendar,
        path: 'https://www.src.am/am/showCalendarPage/117',
      },
      {
        name: 'ՀՀ հարկ վճարողների փնտրման համակարգ',
        icon: Taxpayers,
        path: 'https://www.src.am/am/taxpayerSearchSystemPage/112-ից',
      },
      {
        name: 'Հաճախ հանդիպող հարցերն ու դրանց վերաբերյալ ՊԵԿ տրված իրազեկումները',
        icon: FrequentlyAsked,
        path: 'https://www.src.am/am/awarenessInformationPage/118',
      },
    ],
    importantTools: [
      {
        title: 'Օգտակար հղումներ',
      },
      {
        name: 'Եղանակը ցանկացած վայրում',
        icon: Forecast,
        path: 'https://hy.meteotrend.com/forecast/am',
      },

      {
        name: 'Փոխարժեքները ՀՀ -ում',
        icon: Rate,
        path: 'https://www.rate.am/',
      },
      {
        //  name: 'Հայաստանի Հանրապետության նոտարական պալատն',
        icon: Notary,
        path: 'https://www.e-notary.am/',
      },
      {
        //name: 'ՀԱՅԱՍՏԱՆԻ ԻՐԱՎԱԿԱՆ ՏԵՂԵԿԱՏՎԱԿԱՆ ՀԱՄԱԿԱՐԳ',
        icon: Arlis,
        path: 'https://www.arlis.am/',
      },
      {
        //name: 'Էլեկտրոնային ռեգիստրի',
        icon: ERegister,
        path: 'https://www.e-register.am/am/',
      },
      {
        // name: 'ԴատաԼեքս',
        icon: Datalex,
        path: 'https://datalex.am/',
      },
      {
        // name: '',
        icon: Ekeng,
        path: 'https://www.ekeng.am/',
      },
      {
        // name: 'ԱՐՄՍՏԱՏ',
        icon: Armstat,
        path: 'https://armstat.am/en/',
      },
      {
        name: '',
        icon: Cadastre,
        path: 'https://www.e-cadastre.am/',
      },
    ],
  };

  return (
    <>
      <TopMenu />
      {companySign ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <OrganizationAddForm />
        </div>
      ) : (
        <>
          <div className="useful-tools-block">
            <div className="useful-tools-block-content">
              <div className="useful-tools-panel">
                <div className="tool-item-box basic-tools">
                  {usefulToolsList?.basicTools?.map((tool) => {
                    const { path, name, title, icon } = tool || {};

                    const selectCalendar = path === '/Calendar';
                    const uniqueID = uuidv4();
                    return (
                      <div className="tool-item-content" key={uniqueID}>
                        {title ? (
                          <h3>{title}</h3>
                        ) : (
                          <div
                            className={`use-ful-tools-wrapper ${
                              selectCalendar ? 'last-child' : ''
                            }`}
                          >
                            {selectCalendar && renderNotificationIcon()}

                            <a href={path} rel="noreferrer">
                              {icon && <img src={icon} alt="" />}
                              {name}
                            </a>
                            <div className="hover-block">Coming Soon :D</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="tool-item-box information-sites">
                  {usefulToolsList?.informationSites?.map((site) => {
                    const { path, name, title, icon } = site || {};
                    const uniqueID = uuidv4();
                    return (
                      <div className="tool-item-content " key={uniqueID}>
                        {title ? (
                          <h3>{title}</h3>
                        ) : (
                          <div className="use-ful-tools-wrapper">
                            <a href={path} target="_blank" rel="noreferrer">
                              {icon && <img src={icon} alt="" />}
                              {name}
                            </a>
                            <div className="hover-block">Coming Soon :D</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="useful-tools-panel">
                <div className="tool-item-box important-tools">
                  {usefulToolsList?.importantTools?.map((tool) => {
                    const { path, name, title, icon } = tool || {};
                    const uniqueID = uuidv4();
                    return (
                      <div className="tool-item-content" key={uniqueID}>
                        {title ? (
                          <h3>{title}</h3>
                        ) : (
                          <div className="use-ful-tools-wrapper">
                            <a href={path} target="_blank" rel="noreferrer">
                              {icon && <img src={icon} alt="" />}
                              {name}
                            </a>
                            <div className="hover-block">Coming Soon :D </div>
                            {/* <div className="tool-block">
                              <div className="hover-block">Coming Soon :D </div>
                              <div>Icon</div>
                            </div> */}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {showNotData && (
            <NotificationForm
              notificationData={notificationData}
              closeModal={setShowNotData}
            />
          )}
        </>
      )}
    </>
  );
};

export default UsefulTool;
